import React from 'react';
import { ArrowRight, Shield, Cpu, Lock, Server } from 'lucide-react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-28">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-900 mb-6 sm:text-6xl">
              Your Data, Our AI, Your Solution
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              Enterprise-grade AI solutions that keep your data in your control. Process sensitive information locally with state-of-the-art AI models.
            </p>
            <div className="flex justify-center gap-4">
              <Link 
                to="/meetingblock/try" 
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
              >
                Try MeetingBlock
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link 
                to="/waitlist" 
                className="inline-flex items-center px-6 py-3 rounded-lg border-2 border-gray-300 text-gray-700 font-medium hover:border-gray-400 transition-colors"
              >
                Join Waitlist
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Problem Statement */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            The Privacy Paradox in Modern AI
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Lock,
                title: 'Data Security Risk',
                description: 'Sensitive information leaving your secure environment'
              },
              {
                icon: Server,
                title: '3rd Party Dependence',
                description: 'Reliance on external services for critical operations'
              },
              {
                icon: Shield,
                title: 'Limited Control',
                description: 'Reduced oversight over data processing and storage'
              },
              {
                icon: Cpu,
                title: 'Compliance Concerns',
                description: 'Meeting regulatory requirements across regions'
              }
            ].map((item, index) => (
              <div key={index} className="text-center">
                <div className="mx-auto w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-4">
                  <item.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Local AI, Unlimited Potential
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Process your data locally with enterprise-grade AI models, maintaining complete control and security.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: 'Data Sovereignty',
                description: 'Your sensitive data never leaves your secure environment. Process everything locally.'
              },
              {
                title: 'Advanced Local Processing',
                description: 'State-of-the-art AI models optimized for local deployment and performance.'
              },
              {
                title: 'Seamless Integration',
                description: 'Easily integrate with your existing workflows and security protocols.'
              }
            ].map((item, index) => (
              <div 
                key={index}
                className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
              >
                <h3 className="text-lg font-semibold text-gray-900 mb-3">
                  {item.title}
                </h3>
                <p className="text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* MeetingBlock Showcase */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Introducing MeetingBlock
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                Transform your meeting transcripts into structured, actionable notes with local AI processing. Maintaining your privacy while boosting productivity.
              </p>
              <ul className="space-y-4 mb-8">
                {[
                  'Process meetings locally with AI',
                  'Generate structured notes and action items',
                  'Search and analyze meeting history*',
                  'Maintain complete data privacy'
                ].map((feature, index) => (
                  <li key={index} className="flex items-center gap-3">
                    <svg className="w-5 h-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="flex gap-4">
                <Link 
                  to="/meetingblock/try"
                  className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
                >
                  Try Now
                </Link>
                <Link 
                  to="/meetingblock"
                  className="inline-flex items-center px-6 py-3 rounded-lg border-2 border-gray-300 text-gray-700 font-medium hover:border-gray-400 transition-colors"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              {/* Placeholder for MeetingBlock screenshot/demo */}
              <div className="aspect-video bg-gray-100 rounded-xl flex items-center justify-center">
                <p className="text-gray-500">Product Demo</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ComputeBlock Teaser */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Coming Soon: ComputeBlock
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Enhance your local AI processing capabilities with our enterprise-ready computing solution.
          </p>
          <button className="inline-flex items-center px-6 py-3 rounded-lg border-2 border-gray-300 text-gray-700 font-medium hover:border-gray-400 transition-colors">
            Stay Updated
          </button>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ready to Take Control?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Start with MeetingBlock and experience the power of local AI processing.
          </p>
          <div className="flex justify-center gap-4">
            <Link 
              to="/waitlist"
              className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors"
            >
              Get Started
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <Link 
              to="/waitlist"
              className="inline-flex items-center px-6 py-3 rounded-lg border-2 border-gray-300 text-gray-700 font-medium hover:border-gray-400 transition-colors"
            >
              Contact
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;