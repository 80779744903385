import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Landing from './components/Landing'
import Loading from './components/Loading'
import Layout from './components/Layout'

// Lazy load components for better initial load performance
const MeetingUploader = React.lazy(() => import('./components/MeetingUploader'))
const Waitlist = React.lazy(() => import('./components/Waitlist'))
const About = React.lazy(() => import('./components/About'))

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* Landing page at root */}
            <Route path="/" element={<Landing />} />

            {/* Meeting summarizer routes */}
            <Route
              path="/meetingblock"
              element={
                <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-8 px-4">
                  <div className="max-w-4xl mx-auto">
                    <header className="text-center mb-12">
                      <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Meeting Notes Generator
                      </h1>
                      <p className="text-lg text-gray-600 dark:text-gray-300">
                        Convert your meeting transcripts into structured notes
                      </p>
                    </header>
                    <main>
                      <MeetingUploader />
                    </main>
                  </div>
                </div>
              }
            />

            <Route path="/meetingblock/try" element={<MeetingUploader />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/about" element={<About />} />

            {/* Catch all route for 404s */}
            <Route
              path="*"
              element={
                <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
                  <div className="text-center">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                      404 - Page Not Found
                    </h1>
                    <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                      The page you're looking for doesn't exist.
                    </p>
                    <a
                      href="/"
                      className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 font-medium"
                    >
                      Return Home
                    </a>
                  </div>
                </div>
              }
            />
          </Routes>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
}

export default App;